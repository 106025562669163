import { Dialog, DialogIDs } from './Dialog.cy';
import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';
import { ItemBase, ItemBaseIDs } from '../other-components/ItemBase.cy';
import {
  AddToShoppingListDropdown,
  AddToShoppingListDropdownIDs,
} from '../other-components/AddToShoppingListDropdown.cy';

const {
  buttons: btn,
  namedElements: elm,
  components,
} = utilsData.elementsDataTestIDs;

export class ItemDescriptionDialogIDs extends DialogIDs {
  itemID: ItemBaseIDs;
  breadcrumbsID: string;
  vendorLogoID: string;
  arrowNextBtnID: string;
  arrowBackBtnID: string;
  messageToVendorBtnID: string;
  shareBtnID: string;
  itemDetailsID: string;
  readMoreBtnID: string;
  qtyDropdownBtnID: string;
  qtyDropdownOptionID: string;
  addToCartBtnID: string;
  addToShoppingListBtnID: string;
  addToShoppingListDropdownID: AddToShoppingListDropdownIDs;

  constructor() {
    super(components.dialogs.itemDescription);
    this.itemID = new ItemBaseIDs(this.fullPrefix);
    this.breadcrumbsID = TestIDFormat.element(this.fullPrefix, elm.breadcrumbs);
    this.vendorLogoID = TestIDFormat.element(this.fullPrefix, elm.vendorLogo);
    this.arrowNextBtnID = TestIDFormat.button(this.fullPrefix, btn.arrowNext);
    this.arrowBackBtnID = TestIDFormat.button(this.fullPrefix, btn.arrowBack);
    this.messageToVendorBtnID = TestIDFormat.button(
      this.fullPrefix,
      btn.messageToVendor,
    );
    this.shareBtnID = TestIDFormat.button(this.fullPrefix, btn.share);
    this.itemDetailsID = TestIDFormat.element(this.fullPrefix, elm.details);
    this.readMoreBtnID = TestIDFormat.button(this.fullPrefix, btn.readMore);
    this.qtyDropdownBtnID = TestIDFormat.button(
      this.fullPrefix,
      btn.qtyDropdown,
    );
    this.qtyDropdownOptionID = TestIDFormat.element(
      this.fullPrefix,
      elm.qtyDropdownOption,
    );
    this.addToCartBtnID = TestIDFormat.button(this.fullPrefix, btn.addToCart);
    this.addToShoppingListBtnID = TestIDFormat.button(
      this.fullPrefix,
      btn.addToShoppingList,
    );
    this.addToShoppingListDropdownID = new AddToShoppingListDropdownIDs(
      this.fullPrefix,
    );
  }
}

export class ItemDescriptionDialog extends Dialog<ItemDescriptionDialogIDs> {
  constructor() {
    super(ItemDescriptionDialogIDs);
  }

  get item() {
    return new ItemBase(this.testIDs.itemID);
  }

  get vendorLogo() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.vendorLogoID, timeout);
    };
  }

  get breadcrumbs() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.breadcrumbsID, timeout);
    };
  }

  get arrowNextBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.arrowNextBtnID, timeout);
    };
  }

  get arrowBackBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.arrowBackBtnID, timeout);
    };
  }

  get messageToVendorBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.messageToVendorBtnID, timeout);
    };
  }

  get shareBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.shareBtnID, timeout);
    };
  }

  get itemDetails() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.itemDetailsID, timeout);
    };
  }

  get readMoreBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.readMoreBtnID, timeout);
    };
  }

  get qtyDropdownBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.qtyDropdownBtnID, timeout);
    };
  }

  get qtyDropdownOption() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.qtyDropdownOptionID, timeout);
    };
  }

  get addToCartBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.addToCartBtnID, timeout);
    };
  }

  get addToShoppingListBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.addToShoppingListBtnID, timeout);
    };
  }

  get addToShoppingListDropdown() {
    return new AddToShoppingListDropdown(
      this.testIDs.addToShoppingListDropdownID,
    );
  }
}
