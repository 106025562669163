import { useEffect, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import {
  Dialog,
  Button,
  InputBase,
  ButtonVariantEnum,
  ArrowRight,
} from '@teddly/teddly-ui-components';
import formStyles from '../../../styles/form.module.scss';
import useDisableScroll from '@hooks/useDisableScroll';
import SocialLogin from '../LoginDialog/SocialLogin';
import styles from '@styles/dialog.module.scss';
import { scrollToTheTopOfTheElement } from '@hooks/useWindowDimension';
import {
  EMAIL_MAX_LENGTH,
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
} from '@config/validation';
import messages from '../../../config/validation/messages';
import classNames from 'classnames';
import { SignUpDialogIDs } from '@cypress/components/dialogs/SignUp.cy';
import { useChannelContext } from '@context/ChannelContext';

interface FormDataType {
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  phone?: string;
}

type Props = {
  registerAccount?: (data: FormDataType) => Promise<void | any>;
  isOpen?: boolean;
  fieldErrorMessages?: {
    [key in keyof FormDataType]: string;
  };
  onClose?: () => void;
  onLoginClick?: () => void;
  responseGoogle?: (data: any) => void;
  responseFacebook?: (data: any) => void;
  responseApple?: (data: any) => void;
  testIds: SignUpDialogIDs;
};

const emptyFn = () => null;

const formId = 'sign-up';

function SignUpDialog({
  registerAccount,
  onClose,
  fieldErrorMessages = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    phone: 'Phone number is required!',
  },
  onLoginClick,
  isOpen = false,
  responseGoogle = emptyFn,
  responseFacebook = emptyFn,
  responseApple = emptyFn,
  testIds,
}: Props) {
  useDisableScroll(isOpen);
  const { channelDisplayName } = useChannelContext();
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [phone, setPhone] = useState<string>(null);
  const [dataRegister, setDataRegister] = useState<FormDataType>();
  const [resultsErrors, setResultsErrors] = useState<
    { field: string; message: string; code: string }[]
  >([]);

  const getResultsError = (field: string) => {
    return resultsErrors?.find((errorField) => errorField?.field === field);
  };

  const getIsResultsError = (field: string) => {
    return !!getResultsError(field);
  };

  const setLoadingMode = (value: boolean) => {
    setLoading(value);
  };

  const getResultsErrorMessage = (field: string) => {
    return getResultsError(field)?.message;
  };
  const {
    register,
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormDataType>({ mode: 'onChange' });

  const openLoginForm = () => {
    onLoginClick();
    clearForm();
  };

  const clearForm = () => {
    reset({
      email: '',
      password: '',
      phone: '',
      firstName: '',
      lastName: '',
    });
  };

  const closeDialog = () => {
    clearForm();
    onClose();
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const resultsErrors = await registerAccount(data);
    if (resultsErrors) {
      setResultsErrors(resultsErrors);
      setDataRegister(null);
    } else {
      setResultsErrors([]);
      setDataRegister(data);
    }
    setLoading(false);
    scrollToTheTopOfTheElement('dialogBodyContent');
  };

  const reSend = async () => {
    setLoadingResend(true);
    await registerAccount(dataRegister);
    setLoadingResend(false);
  };

  const getError = (errorMessage: string) => {
    if (errorMessage) {
      return <span style={{ color: '#ef2d2d' }}>{errorMessage}</span>;
    }
  };

  // const getErrorMessageIfIsError = (isError: boolean, message: string) => {
  //   if (isError) {
  //     return getError(message)
  //   }
  // }

  const phoneValidate = ({ value }) => {
    if (value === '') return true;
    return (
      value.match('^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$') !=
      null
    );
  };

  return (
    <Dialog
      // data-testid={ids.dialog}
      isOpen={isOpen}
      onClose={closeDialog}
      closeButtonTestId={testIds.closeBtnID}
      headerTestId={testIds.headerID}
      title={`Sign up to ${channelDisplayName}`}>
      <Dialog.Body testId={testIds.bodyID}>
        {/* <div className={styles.dialogBody}>
          <img
            className={styles.image}
            style={{
              alignSelf: 'center',
              width: '100%',
              height: '100%',
            }}
            src={getServerAssetsImageUrl("delivery-man-with-groceries.png")}
            alt="delivery-man"
          /> */}
        <form
          id={formId}
          onSubmit={handleSubmit(onSubmit)}
          className={styles.dialogBodySectionsContainer}>
          {/* <div className={formStyles.form}> */}
          <div
            data-testid={testIds.titleID}
            className={classNames(
              styles.dialogTitle,
              styles.dialogBodySection,
            )}>
            {`Welcome To ${channelDisplayName}`}`
          </div>
          <div
            className={classNames(
              styles.dialogBodySection,
              styles.socialLoginSection,
            )}>
            <SocialLogin
              appleButtonTestid={testIds.appleSocialLoginBtnID}
              googleButtonTestid={testIds.googleSocialLoginBtnID}
              responseGoogle={responseGoogle}
              responseFacebook={responseFacebook}
              responseApple={responseApple}
              setLoadingMode={setLoadingMode}
            />
          </div>
          <div
            className={classNames(
              styles.dialogBodySection,
              styles.formOrDivider,
            )}>
            or
          </div>

          {dataRegister && (
            <>
              <p>
                <b data-testid={testIds.successMessagePID}>
                  {messages.success}
                </b>
              </p>
              <Button
                disabled={!!loadingResend}
                onClick={reSend}
                title="Re-Send email"
                loading={loadingResend}
                testId={testIds.resendBtnID}
              />
              <br />
            </>
          )}
          <div className={styles.dialogBodySection}>
            <Controller
              name="email"
              control={control}
              rules={{
                maxLength: {
                  value: EMAIL_MAX_LENGTH,
                  message: 'email is more then 25 Characters',
                },
                required: messages.validations.email,
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: messages.validations.email,
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputBase
                  data-testid={testIds.emailInputID}
                  stretch
                  label="Email"
                  value={value}
                  onChange={onChange}
                  footnote={
                    getError(error?.message) || getResultsErrorMessage('email')
                  }
                  errored={
                    error?.message?.length > 0 || getIsResultsError('email')
                  }
                />
              )}
            />

            <div
              className={classNames(
                styles.dialogBodySection,
                styles.horizontalSection,
              )}>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  maxLength: {
                    value: FIRST_NAME_MAX_LENGTH,
                    message: `First name is more than ${FIRST_NAME_MAX_LENGTH} Characters`,
                  },
                  required: messages.validations.firstName,
                  pattern: {
                    value: /^[A-Za-z]+\s*/,
                    message: messages.validations.firstName,
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputBase
                    data-testid={testIds.firstNameInputID}
                    label="First Name"
                    value={value}
                    fullWidth
                    onChange={onChange}
                    footnote={getError(error?.message)}
                    errored={error?.message?.length > 0}
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                rules={{
                  maxLength: {
                    value: LAST_NAME_MAX_LENGTH,
                    message: 'Last name is more then 15 Characters',
                  },
                  required: messages.validations.lastName,
                  pattern: {
                    value: /^[A-Za-z]+\s*/,
                    message: messages.validations.lastName,
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputBase
                    fullWidth
                    data-testid={testIds.lastNameInputID}
                    label="Last Name"
                    value={value}
                    onChange={onChange}
                    footnote={getError(error?.message)}
                    errored={error?.message?.length > 0}
                  />
                )}
              />
            </div>
            {/* <div className={formStyles.line}> */}

            <PatternFormat
              data-testid={testIds.phoneNumberInputID}
              // stretch
              // css={{width:"100%"}}

              format="1(###) ###-####"
              mask="_"
              allowEmptyFormatting={true}
              title="Phone Number"
              className="maxWidth"
              // width={"100%"}
              label="Phone number"
              fullWidth={true}
              {...register('phone', {
                required: messages.validations.phone,
                pattern: {
                  value:
                    /\+?\d{1,9}?[-.\s]?\(?\d{1,9}?\)?[-.\s]?\d{1,9}[-.\s]?\d{1,9}[-.\s]?\d{1,9}/g,
                  message: messages.validations.phone,
                },
              })}
              aria-errormessage="sfsfs"
              // aria-errormessage={
              //   phone == '' ? (
              //     <span style={{ color: '#ef2d2d' }}>
              //       {messages.validations.phone}
              //     </span>
              //   ) : undefined
              // }

              onValueChange={(valueObj) => {
                const { value } = valueObj;
                setValue('phone', value);
              }}
              // displayType={
              //   phone == '' ? (
              //     <span style={{ color: '#ef2d2d' }}>"asdf"</span>
              //   ) : (
              //     ''
              //   )
              // }
              isAllowed={phoneValidate}
              name="phone"
              // error={phone == ''}
              defaultValue={phone}
              // custom

              customInput={InputBase}
            />

            <Controller
              name="password"
              control={control}
              rules={{
                maxLength: {
                  value: PASSWORD_MAX_LENGTH,
                  message: messages.lengthValidations.password,
                },
                minLength: {
                  value: PASSWORD_MIN_LENGTH,
                  message: messages.lengthValidations.password,
                },
                required: messages.validations.password,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputBase
                  data-testid={testIds.passwordInputID}
                  // stretch
                  fullWidth
                  type="password"
                  label="Password"
                  value={value}
                  onChange={onChange}
                  footnote={getError(error?.message)}
                  errored={error?.message?.length > 0}
                />
              )}
            />
          </div>

          {/* </div> */}
        </form>
        {/* </div> */}
      </Dialog.Body>
      <Dialog.Footer testId={testIds.footerID}>
        <Button
          data-testid={testIds.backToLoginBtnID}
          variant={ButtonVariantEnum.NEGATIVE}
          onClick={openLoginForm}
          title="Login"
        />
        <Button
          data-testid={testIds.openAccountSubmitBtnID}
          form={formId}
          onSubmit={handleSubmit(onSubmit)}
          type="submit"
          disabled={!!loading || !isValid || !!dataRegister}
          loading={!!loading}
          title="Open an Account"
        />
      </Dialog.Footer>
    </Dialog>
  );
}

export default SignUpDialog;
