const utilsData = {
  timeouts: {
    minor: 500,
    short: 5000,
    medium: 15000,
    long: 60000,
    xlong: 300000,
  },

  // for uniformity and typos
  elementsDataTestIDs: {
    buttons: {
      // ***specific***
      // landing page
      zipCodeSearch: 'zip-code-search',
      signIn: 'sign-in',
      // header
      logIn: 'log-in',
      accountActions: 'account-actions',
      pickDeliverySlot: 'pick-delivery-slot',
      cart: 'cart',
      // login dialog
      googleSocialLogin: 'google-social-login',
      appleSocialLogin: 'apple-social-login',
      forgotPassword: 'forgot-password',
      openAccount: 'open-account',
      backToLogin: 'back-to-login',
      // sign up dialog
      resend: 'resend',
      // pick address dialog
      changeAddress: 'change-address',
      // pick payment dialog
      changeCard: 'change-card',
      // delivery slot card
      selectTimeSlot: 'select-time-slot',
      getItByFastDelivery: 'get-it-by-fast-delivery',
      // account settings page
      signOut: 'sign-out',
      resetPassword: 'reset-password',
      deleteAccount: 'delete-account',
      // payment method dialog
      addCard: 'add-card',
      // delivery address dialog
      addAddress: 'add-address',
      saveEditAddress: 'save-edit-address',
      // checkout page
      orderNote: 'order-note',
      pay: 'pay',
      itemSubstitutionToggle: 'item-substitution-toggle',
      toivelingToggle: 'toiveling-toggle',
      tip5: 'tip-5',
      tip10: 'tip-10',
      tip15: 'tip-15',
      tip20: 'tip-20',
      tip25: 'tip-25',
      otherTip: 'other-tip',
      otherTipSave: 'other-tip-save',
      // order confirmation dialog
      trackOrder: 'track-order',
      keepShopping: 'keep-shopping',
      // category header
      category: 'category',
      categoryClose: 'category-close',
      // product pages
      filter: 'filter',
      fastDelivery: 'fast-delivery',
      // item details dialog
      addToShoppingList: 'add-to-shopping-list',
      messageToVendor: 'message-to-vendor',
      readMore: 'read-more',
      qtyDropdown: 'quantity-dropdown',
      addToCart: 'add-to-cart',
      arrowNext: 'arrow-next',
      arrowBack: 'arrow-back',

      // cart
      checkout: 'checkout',
      allItems: 'all-items',
      showBreakdown: 'show-breakdown',
      // shopping list
      createShoppingList: 'create-shopping-list',
      selectItemsToCart: 'select-items-to-cart',
      addAll: 'add-all',
      addSelectedToCart: 'add-selected-to-cart',

      // my orders
      orderHistoryTab: 'order-history-tab',
      myPopularItemsTab: 'my-popular-items-tab',
      orderAgain: 'order-again',
      editDeliveryDetails: 'edit-delivery-details',
      cancelOrder: 'cancel-order',
      editOrder: 'edit-order',
      yes: 'yes',
      no: 'no',
      startEditing: 'start-editing',
      exitEditMode: 'exit-edit-mode',
      saveChanges: 'save-changes',
      keepEditing: 'keep-editing',

      // ***general***
      close: 'close',
      submit: 'submit',
      cancel: 'cancel',
      expand: 'expand',
      edit: 'edit',
      save: 'save',
      apply: 'apply',
      remove: 'remove',
      delete: 'delete',
      clear: 'clear',
      change: 'change',
      addNew: 'add-new',
      search: 'search',
      toggle: 'toggle',
      chip: 'chip',
      share: 'share',
      loadMore: 'load-more',
      menu: 'menu',
    },

    inputFields: {
      // **general**
      zipCode: 'zip-code',
      search: 'search',
      email: 'email',
      password: 'pasword',
      currentPassword: 'current-pasword',
      newPassword: 'new-pasword',
      confirmNewPassword: 'confirm-new-pasword',
      firstName: 'first-name',
      lastName: 'last-name',
      phoneNumber: 'phone-number',
      address: 'address',
      addressSearch: 'address-search',
      streetAddress: 'street-address',
      apartment: 'apartment',
      city: 'city',
      state: 'state',
      note: 'note',
      cardNumber: 'card-number',
      expiration: 'expiration-date',
      cvv: 'cvv',
      name: 'name',
      otherTip: 'other-tip',
      coupon: 'coupon',
      giftCard: 'gift-card',
    },

    namedElements: {
      header: 'header',
      logo: 'logo',
      title: 'title',
      body: 'body',
      weekDay: 'week-day',
      date: 'date',
      description: 'description',
      footer: 'footer',
      checkbox: 'checkbox',

      // item details
      vendorLogo: 'vendor-logo',
      breadcrumbs: 'breadcrumbs',
      image: 'image',
      name: 'name',
      details: 'details',
      price: 'price',
      priceUnit: 'price-unit',
      unitSize: 'unit-size',
      qtyDropdownOption: 'quantity-dropdown-option',

      //table
      tableHeader: 'table-header',
      tableRow: 'table-row',

      // shopping list
      addItemsCheckboxID: 'add-items-checkbox',

      // order summary
      orderNumber: 'order-number',
      orderDate: 'order-date',
      orderStatusTag: 'order-status-tag',
      orderConfirmed: 'order-confirmed',
      orderInTransit: 'order-in-transit',
      orderDelivered: 'order-delivered',
      subtotal: 'subtotal',
      tax: 'tax',
      delivery: 'delivery',
      deliveryFee: 'delivery-fee',
      payemetMethod: 'payment-method',
      itemSubstitution: 'item-substitution',
      note: 'note',

      tip: 'tip',
      discount: 'discount',
      coupon: 'coupon',
      giftCard: 'gift-card',
      total: 'total',
      deliveryAddress: 'delivery-address',
      deliveryTime: 'delivery-time',
      categorySlideBar: 'category-slide-bar',
      sucessMessageP: 'success-message-paragraph',
    },

    components: {
      unitController: {
        title: 'unit-controller',
        add: 'add',
        value: 'value',
        subtract: 'subtract',
        delete: 'delete',
      },
      loaders: { splashScreen: 'splash-screen', progressBar: 'progress-bar' },
      snackbar: {
        title: 'snackbar',
        success: 'sucess',
        error: 'error',
        warning: 'warning',
      },
      dropdown: {
        title: 'dropdown',
        addToShoppingList: {
          title: 'add-to-shopping-list',
          listName: 'list-name',
        },
        addressIcon: 'address-icon', // ??
      },

      dialogs: {
        title: 'dialog',
        scheduleDelivery: 'schedule-delivery',
        signIn: 'sign-in',
        signUp: 'sign-up',
        forgotPassword: 'forgot-password',
        // account settings page
        addPaymentMethod: 'add-payment-method',
        pickPaymentMethod: 'pick-payment-method',
        addDeliveryAddress: 'add-delivery-address',
        pickDeliveryAddress: 'pick-delivery-address',
        resetPassword: 'reset-password',
        // home page/search results
        filterSearchResults: 'filter-search-results',
        itemDescription: 'item-description',
        addToShoppingListFromItemDetails: 'add-from-item', // name?
        messageToVendor: 'message-to-vendor',
        // cart
        cart: 'cart', // waiting for item details collapsible?
        orderSummary: 'order-summary',
        // checkout
        checkoutNote: 'checkout-note',
        orderConfirmation: 'order-confirmation',
        // shopping list
        createShoppingList: 'create-shopping-list',
        editShoppingListName: 'edit-shopping-list-name',
        deleteShoppingList: 'delete-shopping-list',
        selectFromShoppingList: 'select-from-shopping-list', // waiting for item details collapsible?
        // my orders
        orderAgainCart: 'order-again-cart',
        cancelOrder: 'cancel-order',
        editOrder: 'edit-order', //done
        editOrderMode: 'edit-order-mode',
        exitEditOrderMode: 'exit-edit-order-mode', //done
      },

      cards: {
        title: 'card',
        deliveryAddress: 'delivery-address',
        paymentMethod: 'payment-method',
        deliveryTime: 'delivery-time',
        DeliveryDay: 'delivery-day',
        specialRequests: 'special-requests',
        tipTheDriver: 'tip-the-driver',
        orderBreakdown: 'order-breakdown',
        item: 'item',
        vendor: 'vendor',
      },

      collapsible: {
        title: 'collapsible', // Class done
        accountSettings: {
          // title: 'account-info', not sure it is necessary
          personalInfo: 'personal-info',
          paymentMethod: 'payment-method',
          deliveryAddress: 'delivery-address',
          loginAndSecurity: 'login-and-security',
        },
        filterOptions: {
          sortBy: 'sort-by',
          brand: 'brand',
          vendor: 'vendor',
          collection: 'collection',
          supervision: 'supervision', // not done, exist in live not in dev
        },
        item: 'item',
        orderBreakdown: {
          // title: 'order-breakdown',
          orderSummary: 'order-summary',
          addCoupon: 'add-coupon',
          addGiftCard: 'add-gift-card',
          returnPolicy: 'returm-policy',
        },
      },
      navigation: {
        header: 'header',
        sideNavBar: 'side-navigaion-bar',
      },
      pages: {
        title: 'page',
        landing: 'landing',
        home: 'home',
        products: 'products',
        checkout: 'checkout', //

        options: {
          title: 'options',
          accountSettings: 'account-settings',
          shoppingLists: 'shopping-lists',
          shoppingListDetails: 'shopping-list-details',
          myOrders: 'my-orders',
          orderDetails: 'order-details',
        },
        categories: {
          title: 'categories',
          grocery: 'grocery',
          household: 'household',
          judaica: 'judaica',
          pharmacy: 'pharmacy',
        },
        stores: 'stores',
        pros: 'pros',
        restaurants: 'restaurants',
      },
    },
  },

  pagePathNames: {
    homePage: '/',
    groceryPage: '/grocery',
    housholdPage: '/household',
    pharmacyPage: '/pharmacy',
    judaicaPage: '/judaica',
    clothesAndToysPage: '/clothes-and-toys',
    storesPage: '/stores',
    myPopularItemsPage: '/my-popular-items',
    myShoppingListsPage: '/shopping-lists',
    myAccountSettingPage: '/my-account',
    returnPolicyPage: '/return-policy',
    privacyPolicyPage: '/terms-privacy',
    checkoutPage: '/checkout',
    orderHistoryPage: '/order-history',
    orderDetailsPage: '/order-details',
  },
};

export default utilsData;
