import { createPaymentSource } from '@app/payment';
import { useNetworkState } from '@hooks/useNetworkState';
import CardknoxPaymentDetailsForm, {
  TokenData,
} from '../../forms/PaymentDetails/CardknoxPaymentDetailsForm';
import { IAddress } from 'teddly-sdk/lib/api/Checkout/types';
import { useCheckoutPaymentContext } from '@context/CheckoutPaymentContext';
import { CountryCode } from 'teddly-sdk';
import { PaymentGatewayType } from '@app/payment/types';
import {
  SnackbarVariant,
  usePageLayoutContext,
} from '@context/PageLayoutContext';
import { StripeCardData } from '../../hooks';
import { AddPaymentMethodDialogIDs } from '@cypress/components/dialogs/AddPaymentMethod.cy';

type Props = {
  onAddPaymentCard: (address: IAddress) => void;
  onBillingAddress: (address: IAddress) => void;
  testIds: AddPaymentMethodDialogIDs;
};

export default function CardknoxAddPaymentCard({
  onAddPaymentCard,
  onBillingAddress,
  testIds,
}: Props) {
  const { state, setLoading, setError, setCompleted } = useNetworkState();
  const {
    closeCurrentDialog,
    changeCardHandler,
    openPickPaymentForm,
    isOpenAddPaymentForm,
    isPaymentLoading,
  } = useCheckoutPaymentContext();

  const { addSnackbar, closeSnackbar } = usePageLayoutContext();

  const loading = state.status === 'loading' || isPaymentLoading;

  const handleSubmit = ({
    token,
    exp,
    billingAddress,
  }: {
    token: TokenData;
    exp: string;
    billingAddress?: IAddress;
  }) => {
    setLoading();
    onBillingAddress({
      ...billingAddress,
    });
    createPaymentSource(PaymentGatewayType.CARDKNOX, {
      authenticationData: JSON.stringify({ token: token.xToken, exp }),
      billingAddress: {
        ...billingAddress,
        ['tag']: undefined,
        country: billingAddress?.country?.code as CountryCode,
      },
    })
      .then(({ success, errors }) => {
        if (success) {
          setCompleted({ token });
          const selectedCardInfo: StripeCardData = {
            gateway: 'cardknox',
            creditCardInfo: {
              billingAddress: {
                city: billingAddress?.city,
                companyName: billingAddress?.companyName,
                country: billingAddress?.country?.code,
                countryArea: billingAddress?.countryArea,
                firstName: billingAddress?.firstName,
                lastName: billingAddress?.lastName,
                phone: billingAddress?.phone,
                postalCode: billingAddress?.postalCode,
                note: billingAddress?.note,
                streetAddress1: billingAddress?.streetAddress1,
                streetAddress2: billingAddress?.streetAddress2,
                __typename: 'CardBillingAddress',
              },
              __typename: 'CreditCard',
              expYear: exp.slice(-2),
              expMonth: exp.slice(0, 2),
              lastDigits: token,
              brand: token.xTokenType,
              cardId: token.xToken,
              token: token.xToken,
              firstDigits: null,
            },
          };
          changeCardHandler(selectedCardInfo);
          onAddPaymentCard(billingAddress);
          addSnackbar({
            id: 'success',
            onClose: () => closeSnackbar('success'),
            message: 'Payment card is added successfully!',
            variant: SnackbarVariant.SUCCESS,
          });
        }
        if (errors?.length > 0) {
          throw errors[0];
        }
      })
      .catch((e) => setError(e));
  };

  const handleClose = () => {
    closeCurrentDialog();
    openPickPaymentForm();
  };

  return (
    <>
      <CardknoxPaymentDetailsForm
        isOpen={isOpenAddPaymentForm}
        onClose={handleClose}
        onSubmit={handleSubmit}
        loading={loading}
        testIds={testIds}
      />
    </>
  );
}
