import { DialogIDs, Dialog } from './Dialog.cy';
import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';

const {
  buttons: btn,
  inputFields: field,
  namedElements: elm,
  components,
} = utilsData.elementsDataTestIDs;

export class SignUpDialogIDs extends DialogIDs {
  appleSocialLoginBtnID: string;
  googleSocialLoginBtnID: string;
  openAccountSubmitBtnID: string;
  backToLoginBtnID: string;
  emailInputID: string;
  firstNameInputID: string;
  lastNameInputID: string;
  phoneNumberInputID: string;
  passwordInputID: string;
  successMessagePID: string;
  resendBtnID: string;

  constructor() {
    super(components.dialogs.signUp);

    this.appleSocialLoginBtnID = TestIDFormat.button(
      this.fullPrefix,
      btn.appleSocialLogin,
    );
    this.googleSocialLoginBtnID = TestIDFormat.button(
      this.fullPrefix,
      btn.googleSocialLogin,
    );
    this.emailInputID = TestIDFormat.inputField(this.fullPrefix, field.email);
    this.firstNameInputID = TestIDFormat.inputField(
      this.fullPrefix,
      field.firstName,
    );
    this.lastNameInputID = TestIDFormat.inputField(
      this.fullPrefix,
      field.lastName,
    );
    this.phoneNumberInputID = TestIDFormat.inputField(
      this.fullPrefix,
      field.phoneNumber,
    );
    this.passwordInputID = TestIDFormat.inputField(
      this.fullPrefix,
      field.password,
    );
    this.openAccountSubmitBtnID = TestIDFormat.button(
      this.fullPrefix,
      `${btn.openAccount}-${btn.submit}`,
    );
    this.backToLoginBtnID = TestIDFormat.button(
      this.fullPrefix,
      btn.backToLogin,
    );
    this.successMessagePID = TestIDFormat.element(
      this.fullPrefix,
      elm.sucessMessageP,
    );
    this.resendBtnID = TestIDFormat.button(this.fullPrefix, btn.resend);
  }
}

export class SignUpDialog extends Dialog<SignUpDialogIDs> {
  constructor() {
    super(SignUpDialogIDs);
  }

  get appleSocialLoginBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.appleSocialLoginBtnID, timeout);
    };
  }

  get googleSocialLoginBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.googleSocialLoginBtnID, timeout);
    };
  }

  get emailInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.emailInputID, timeout);
    };
  }

  get firstNameInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.firstNameInputID, timeout);
    };
  }

  get lastNameInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.lastNameInputID, timeout);
    };
  }

  get phoneNumberInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.phoneNumberInputID, timeout);
    };
  }

  get passwordInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.passwordInputID, timeout);
    };
  }

  get openAccountSubmitBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.openAccountSubmitBtnID, timeout);
    };
  }

  get backToLoginBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.backToLoginBtnID, timeout);
    };
  }

  get successMessageP() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.successMessagePID, timeout);
    };
  }

  get resendBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.resendBtnID, timeout);
    };
  }
}
